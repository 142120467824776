.learn {
  background-color: var(--btn-color);
  padding: 120px 0 205px;
  color: var(--light-color);
  @include small-tablet {
    padding: 30px 0 150px;
  }
  .title {
    max-width: 755px;
    margin-bottom: 40px;
    @include mini-tablet {
      margin-bottom: 20px;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    max-width: 960px;
    @include mini-tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__text {
    max-width: 526px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    span {
      font-weight: 600;
    }
    @include mini-tablet {
      margin-bottom: 55px;

    }
    @include small-tablet {
      font-size: 16px;
    }
  }
  &__time {
    position: relative;
    margin-left: auto;
    @include mini-tablet {
      margin-left: initial;
    }
    &-text {
      position: relative;
      font-weight: 600;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.02em;
      background-color: var(--btn-color);
      border: 4px solid var(--light-color);
      border-radius: 16px;
      padding: 16px 27px;
      z-index: 1;
    }
    &::before {
      position: absolute;
      content: '';
      bottom: -95px;
      left: 45px;
      width: 460px;
      height: 460px;
      background-image: url(../img/learn__img.svg);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;
      @include mini-tablet {
        transform: rotate(45deg);
        width: 290px;
        height: 290px;
        left: 145px;
        bottom: auto;
        top: -55px;
      }
    }
  }
}
