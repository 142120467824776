.header {
  position: absolute;
  top: 0px;
  padding: 20px 0;
  width: 100%;
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include small-tablet {
    top: 10px;
  }
}

.logo {
  display: inline-block;
  @include small-tablet {
    margin-right: auto;
    width: 146px;
  }
}




.dropbtn {
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
  color: var(--light-color);
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 30px;
  background-color: var(--light-color);
  width: 80px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px;
  left: -25px;
}
.dropdown-content.active {
  display: block;
}
.dropbtn
{
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 14px 20px 14px 25px;
}

.dropbtn::after {
  background: rgba(0, 0, 0, 0) url("../img/arrow.svg") no-repeat scroll center center;
  content: "";
  height: 5px;
  position: absolute;
  right: 0;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.dropbtn-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-th {
  background: url("../img/th.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-id {
  background: url("../img/id.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropdown-link {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: var(--dark-color);
  }
}
.dropdown-link-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-id {
  background: url("../img/id.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-th {
  background: url("../img/th.jpg") no-repeat left center;
  background-size: 23px 16px;
}

/* Links inside the dropdown */
.dropdown-link {
  padding-left: 30px;
  text-decoration: none;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-color);
}



