.intro {
  padding-top: calc(144px + 80px);
  background-image: url(../img/main__bg-desk.svg);
  background-repeat: no-repeat;
  background-position: top left calc((100vw - 200px) / 2);
  @include small-tablet {
    background-image: url(../img/main__bg-mob.svg);
    background-position: top center;
    padding-top: 85px;
  }
  @include mobile {
    background-size: contain;
  }
  &__title {
    max-width: 860px;
    font-weight: 600;
    font-size: 96px;
    line-height: 110%;
    letter-spacing: -0.02em;
    margin-bottom: 25px;
    span {
      display: block;
      color: var(--green-color-accent)
    }
    @include small-tablet {
      font-size: 48px;
      margin-bottom: 10px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.02em;
    margin-bottom: 45px;
    max-width: 550px;
    @include small-tablet {
      font-size: 20px;
      margin-bottom: 290px;
    }
  }
}

.time {
  display: flex;
  align-items: center;
  background-color: var(--green-color-accent);
  border-radius: 16px;
  padding: 32px 43px 32px 32px;
  max-width: 685px;
  margin-bottom: 165px;
  @include small-tablet {
    padding: 11px 12px;
    max-width: 345px;
    margin-bottom: 65px;
  }
  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: var(--light-color);
    @include small-tablet {
      font-size: 12px;
    }
  }
  &__date {
    display: block;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.02em;
    margin-top: 5px;
    @include small-tablet {
      font-size: 20px;
      margin-top: 3px;
    }
  }
  .btn {
    margin-left: auto;
    @include small-tablet {
      min-width: 143px;
      padding: 11px;
      max-width: 150px;
    }
  }
}

.webinar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  @include high-tablet {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  &__item {
    display: flex;
    align-items: center;
    min-height: 450px;
    background-repeat: no-repeat;
    background-size: contain;
    &:nth-child(1) {
      background-image: url(../img/webinar__img-1-desk.svg);
      background-position: center center;
      @include high-tablet {
        background-image: url(../img/webinar__img-1-mob.svg);
      }
    }
    &:nth-child(2) {
      background-image: url(../img/webinar__img-2-desk.svg);
      background-position: bottom right;
      @include high-tablet {
        background-image: url(../img/webinar__img-2-mob.svg);
        background-position: center center;
      }
    }
    &:nth-child(3) {
      background-image: url(../img/webinar__img-3-desk.svg);
      background-position: bottom right;
      @include high-tablet {
        background-image: url(../img/webinar__img-3-mob.svg);
        background-position: center center;
      }
    }
    @include high-tablet {
      min-height: 200px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.02em;
    @include small-tablet {
      font-size: 24px;
    }
  }
}
