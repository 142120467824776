.register {
  position: relative;
  background-color: var(--btn-color);
  color: var(--light-color);
  padding-top: 100px;
  @include small-tablet {
    padding-top: 65px;
  }
  &__wrap {
    position: relative;
    text-align: center;
    padding: 100px 0 155px;
    z-index: 5;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: calc((100vw - 1475px) / 2);
      width: 1170px;
      height: 514px;
      background-image: url(../img/frame.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left bottom;
      z-index: -1;
      @include small-tablet {
        background-image: url(../img/frame.svg);
        background-size: 100% 100%;
        background-position: top left;
        height: 100%;
        right: calc((100vw - 1371px) / 2);
      }
    }
    @include small-tablet {
      padding: 65px 15px 120px;
    }
  }
  &::after {
    position: absolute;
    content: "";
    width: 300px;
    height: 300px;
    top: 150px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: linear-gradient(235.43deg, #526BFF 21.46%, #3A55F7 102.11%);
    filter: blur(40px);
    opacity: 0.9;
    z-index: 0;
  }
  &__title {
    font-weight: 600;
    font-size: 48px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 15px;
    @include mobile {
      font-size: 32px;
      margin-bottom: 5px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    max-width: 520px;
    margin: auto;
    margin-bottom: 25px;
    span {
      color: var(--green-color-accent);
    }
    @include mobile {
      font-size: 16px;
      margin-bottom: 11px;
      max-width: 225px;
    }
  }
}
