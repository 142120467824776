:root {
  --font-family: "WorkSans", sans-serif;
  --content-width: 1300px;
  --content-fluid-width: 1440px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));
  --light-color: #ffffff;
  --dark-color: #000000;
  --btn-color: #3A55F7;
  --btn-hover: #1937EF;
  --btn-white-hover: #e7eaff;
  --dark-bg: #1B1B1B;
  --green-color: #20A354;
  --accent-color: #A3FFDE;
  --green-color-accent: #55D086;
}

/* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansMediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "WorkSans";
  src: url("../fonts/../fonts/WorkSansBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  color: var(--dark-color);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

html {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

b, strong {
  font-weight: 700;
}

a:hover, a:active, a:focus {
  color: inherit;
  text-decoration: none;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
}

a, li, button {
  outline: none !important;
}

.site-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  font-family: "Inter";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 18px 25px;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  min-width: 221px;
  min-height: 63px;
  color: var(--light-color);
  border-radius: 15px;
  background-color: var(--btn-color);
  transition: all 0.3s ease;
}
.btn:hover, .btn:active, .btn:focus {
  color: var(--light-color);
  background-color: var(--btn-hover);
}
@media (max-width: 768px) {
  .btn {
    font-size: 16px;
    line-height: 130%;
    min-height: 56px;
  }
}
@media (max-width: 576px) {
  .btn {
    width: 100%;
    padding: 18px 25px;
    min-width: 100%;
  }
}
.btn__white {
  background-color: var(--light-color);
  color: var(--btn-color);
}
.btn__white:hover {
  background-color: var(--btn-white-hover);
  color: var(--btn-color);
}
.btn-green {
  background-color: var(--green-color-accent);
  min-width: 336px;
  min-height: 57px;
}
.btn-green:hover {
  background-color: var(--green-color);
}

.btn__header {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: var(--dark-color);
  border: 1px solid var(--dark-color);
  border-radius: 8px;
  padding: 6px 8px;
  margin-left: auto;
  margin-right: 20px;
}
.btn__header:hover, .btn__header:active, .btn__header:focus {
  color: var(--light-color);
  background-color: var(--btn-color);
  border: 1px solid var(--btn-color);
}
@media (max-width: 576px) {
  .btn__header {
    display: none;
  }
}

.form__btn {
  padding: 16px 25px;
  max-height: 52px;
  max-width: 336px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .form__btn {
    width: 100%;
  }
}

.btn__center {
  text-align: center;
}

.slide__btn {
  stroke: var(--dark-color);
}
@media (max-width: 576px) {
  .slide__btn {
    stroke: var(--light-color);
    height: 23px;
  }
}

.title {
  font-weight: 600;
  font-size: 64px;
  line-height: 130%;
  letter-spacing: -0.02em;
}
.title-white {
  color: var(--light-color);
}
.title span {
  color: var(--green-color-accent);
}
@media (max-width: 768px) {
  .title {
    font-size: 48px;
    line-height: 130%;
  }
}
.title-blue {
  color: var(--btn-color);
}

.header {
  position: absolute;
  top: 0px;
  padding: 20px 0;
  width: 100%;
}
.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .header {
    top: 10px;
  }
}

.logo {
  display: inline-block;
}
@media (max-width: 768px) {
  .logo {
    margin-right: auto;
    width: 146px;
  }
}

.dropbtn {
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 10;
  color: var(--light-color);
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 30px;
  background-color: var(--light-color);
  width: 80px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px;
  left: -25px;
}

.dropdown-content.active {
  display: block;
}

.dropbtn {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 14px 20px 14px 25px;
}

.dropbtn::after {
  background: rgba(0, 0, 0, 0) url("../img/arrow.svg") no-repeat scroll center center;
  content: "";
  height: 5px;
  position: absolute;
  right: 0;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.dropbtn-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-th {
  background: url("../img/th.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-id {
  background: url("../img/id.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropbtn-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 28px 20px;
}

.dropdown-link {
  margin-bottom: 10px;
}
.dropdown-link:last-child {
  margin-bottom: 0;
}
.dropdown-link:hover {
  color: var(--dark-color);
}

.dropdown-link-en {
  background: url("../img/en.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-id {
  background: url("../img/id.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-vn {
  background: url("../img/vn.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-my {
  background: url("../img/my.jpg") no-repeat left center;
  background-size: 23px 16px;
}

.dropdown-link-th {
  background: url("../img/th.jpg") no-repeat left center;
  background-size: 23px 16px;
}

/* Links inside the dropdown */
.dropdown-link {
  padding-left: 30px;
  text-decoration: none;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-color);
}

.intro {
  padding-top: 224px;
  background-image: url(../img/main__bg-desk.svg);
  background-repeat: no-repeat;
  background-position: top left calc((100vw - 200px) / 2);
}
@media (max-width: 768px) {
  .intro {
    background-image: url(../img/main__bg-mob.svg);
    background-position: top center;
    padding-top: 85px;
  }
}
@media (max-width: 576px) {
  .intro {
    background-size: contain;
  }
}
.intro__title {
  max-width: 860px;
  font-weight: 600;
  font-size: 96px;
  line-height: 110%;
  letter-spacing: -0.02em;
  margin-bottom: 25px;
}
.intro__title span {
  display: block;
  color: var(--green-color-accent);
}
@media (max-width: 768px) {
  .intro__title {
    font-size: 48px;
    margin-bottom: 10px;
  }
}
.intro__text {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin-bottom: 45px;
  max-width: 550px;
}
@media (max-width: 768px) {
  .intro__text {
    font-size: 20px;
    margin-bottom: 290px;
  }
}

.time {
  display: flex;
  align-items: center;
  background-color: var(--green-color-accent);
  border-radius: 16px;
  padding: 32px 43px 32px 32px;
  max-width: 685px;
  margin-bottom: 165px;
}
@media (max-width: 768px) {
  .time {
    padding: 11px 12px;
    max-width: 345px;
    margin-bottom: 65px;
  }
}
.time__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: var(--light-color);
}
@media (max-width: 768px) {
  .time__text {
    font-size: 12px;
  }
}
.time__date {
  display: block;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.02em;
  margin-top: 5px;
}
@media (max-width: 768px) {
  .time__date {
    font-size: 20px;
    margin-top: 3px;
  }
}
.time .btn {
  margin-left: auto;
}
@media (max-width: 768px) {
  .time .btn {
    min-width: 143px;
    padding: 11px;
    max-width: 150px;
  }
}

.webinar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}
@media (max-width: 992px) {
  .webinar {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
.webinar__item {
  display: flex;
  align-items: center;
  min-height: 450px;
  background-repeat: no-repeat;
  background-size: contain;
}
.webinar__item:nth-child(1) {
  background-image: url(../img/webinar__img-1-desk.svg);
  background-position: center center;
}
@media (max-width: 992px) {
  .webinar__item:nth-child(1) {
    background-image: url(../img/webinar__img-1-mob.svg);
  }
}
.webinar__item:nth-child(2) {
  background-image: url(../img/webinar__img-2-desk.svg);
  background-position: bottom right;
}
@media (max-width: 992px) {
  .webinar__item:nth-child(2) {
    background-image: url(../img/webinar__img-2-mob.svg);
    background-position: center center;
  }
}
.webinar__item:nth-child(3) {
  background-image: url(../img/webinar__img-3-desk.svg);
  background-position: bottom right;
}
@media (max-width: 992px) {
  .webinar__item:nth-child(3) {
    background-image: url(../img/webinar__img-3-mob.svg);
    background-position: center center;
  }
}
@media (max-width: 992px) {
  .webinar__item {
    min-height: 200px;
  }
}
.webinar__text {
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.02em;
}
@media (max-width: 768px) {
  .webinar__text {
    font-size: 24px;
  }
}

.learn {
  background-color: var(--btn-color);
  padding: 120px 0 205px;
  color: var(--light-color);
}
@media (max-width: 768px) {
  .learn {
    padding: 30px 0 150px;
  }
}
.learn .title {
  max-width: 755px;
  margin-bottom: 40px;
}
@media (max-width: 850px) {
  .learn .title {
    margin-bottom: 20px;
  }
}
.learn__wrap {
  display: flex;
  align-items: center;
  max-width: 960px;
}
@media (max-width: 850px) {
  .learn__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
.learn__text {
  max-width: 526px;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
}
.learn__text span {
  font-weight: 600;
}
@media (max-width: 850px) {
  .learn__text {
    margin-bottom: 55px;
  }
}
@media (max-width: 768px) {
  .learn__text {
    font-size: 16px;
  }
}
.learn__time {
  position: relative;
  margin-left: auto;
}
@media (max-width: 850px) {
  .learn__time {
    margin-left: initial;
  }
}
.learn__time-text {
  position: relative;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.02em;
  background-color: var(--btn-color);
  border: 4px solid var(--light-color);
  border-radius: 16px;
  padding: 16px 27px;
  z-index: 1;
}
.learn__time::before {
  position: absolute;
  content: "";
  bottom: -95px;
  left: 45px;
  width: 460px;
  height: 460px;
  background-image: url(../img/learn__img.svg);
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}
@media (max-width: 850px) {
  .learn__time::before {
    transform: rotate(45deg);
    width: 290px;
    height: 290px;
    left: 145px;
    bottom: auto;
    top: -55px;
  }
}

.team {
  background-color: var(--btn-color);
}
@media (max-width: 992px) {
  .team .container-fluid {
    padding-right: 0;
  }
}
.team__wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 992px) {
  .team__wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .team__wrap {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.team__item {
  border: 1px solid rgba(163, 255, 222, 0.5);
  padding: 25px 40px 0 40px;
  color: var(--light-color);
}
.team__item:nth-child(-n+3) {
  border-bottom: none;
  padding-bottom: 110px;
  transform: translateY(1px);
}
@media (max-width: 992px) {
  .team__item:nth-child(-n+3) {
    padding-bottom: 0;
  }
}
.team__item:nth-child(1) {
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.team__item:nth-child(2) {
  border-bottom: 1px solid rgba(163, 255, 222, 0.5);
}
.team__item:nth-child(3) {
  border-left: none;
  border-right: none;
}
.team__item:nth-child(4) {
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.team__item:nth-child(5) {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to bottom, rgba(163, 255, 222, 0.5), rgba(163, 255, 222, 0)) 1 100%;
  border-bottom: none;
}
.team__item:nth-child(6) {
  border-left: none;
  border-right: none;
  border-bottom: none;
}
@media (max-width: 992px) {
  .team__item {
    border: none !important;
  }
}
@media (max-width: 768px) {
  .team__item {
    flex: 0 0 auto;
    width: 340px;
    padding: 25px 40px 0 0px;
  }
}
.team__img {
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .team__img {
    width: 150px;
    height: 150px;
  }
}
.team__experience {
  position: absolute;
  left: 145px;
  top: 24px;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.02em;
  background: linear-gradient(98.83deg, #55D086 7.54%, #3A4FCD 75.18%);
  border-radius: 15px;
}
@media (max-width: 992px) {
  .team__experience {
    font-size: 16px;
    padding: 8px 13px;
    width: 170px;
  }
}
@media (max-width: 576px) {
  .team__experience {
    left: 120px;
  }
}
.team__name {
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.02em;
}
@media (max-width: 768px) {
  .team__name {
    font-size: 24px;
  }
}
.team__post, .team__techniques, .team__technique {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.02em;
}
.team__technique {
  font-weight: 600;
}
.team__post {
  margin-bottom: 25px;
}
@media (max-width: 576px) {
  .team__post {
    margin-bottom: 10px;
  }
}
.team__techniques {
  max-width: 375px;
}

.register {
  position: relative;
  background-color: var(--btn-color);
  color: var(--light-color);
  padding-top: 100px;
}
@media (max-width: 768px) {
  .register {
    padding-top: 65px;
  }
}
.register__wrap {
  position: relative;
  text-align: center;
  padding: 100px 0 155px;
  z-index: 5;
}
.register__wrap::after {
  position: absolute;
  content: "";
  top: 0;
  right: calc((100vw - 1475px) / 2);
  width: 1170px;
  height: 514px;
  background-image: url(../img/frame.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
  z-index: -1;
}
@media (max-width: 768px) {
  .register__wrap::after {
    background-image: url(../img/frame.svg);
    background-size: 100% 100%;
    background-position: top left;
    height: 100%;
    right: calc((100vw - 1371px) / 2);
  }
}
@media (max-width: 768px) {
  .register__wrap {
    padding: 65px 15px 120px;
  }
}
.register::after {
  position: absolute;
  content: "";
  width: 300px;
  height: 300px;
  top: 150px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: linear-gradient(235.43deg, #526BFF 21.46%, #3A55F7 102.11%);
  filter: blur(40px);
  opacity: 0.9;
  z-index: 0;
}
.register__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 15px;
}
@media (max-width: 576px) {
  .register__title {
    font-size: 32px;
    margin-bottom: 5px;
  }
}
.register__text {
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  max-width: 520px;
  margin: auto;
  margin-bottom: 25px;
}
.register__text span {
  color: var(--green-color-accent);
}
@media (max-width: 576px) {
  .register__text {
    font-size: 16px;
    margin-bottom: 11px;
    max-width: 225px;
  }
}

.about {
  position: relative;
  padding: 90px 0 0;
  background-color: var(--light-color);
  background-image: url(../img/about__bg.svg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;
  z-index: 10;
}
.about:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 900px;
  background-image: url(../img/ball.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  z-index: -1;
}
@media (max-width: 576px) {
  .about:after {
    background-image: url(../img/ball-mob.png);
  }
}
@media (max-width: 768px) {
  .about {
    padding: 50px 0 0;
    background-image: none;
  }
}
.about .title {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .about .title {
    margin-bottom: 20px;
  }
}
.about__text {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  max-width: 585px;
}
.about__text span {
  font-weight: 600;
}
.about__text-mb {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .about__text-mb {
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .about__text {
    font-size: 16px;
  }
}
.about__wrap {
  padding-left: 120px;
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  .about__wrap {
    padding-left: 0;
  }
}
@media (max-width: 992px) {
  .about__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
.about__left {
  margin-right: 30px;
}
@media (max-width: 992px) {
  .about__left {
    margin-bottom: 40px;
    margin-right: 0;
  }
}
.about .btn {
  min-width: 300px;
}
.about .btn__center {
  padding: 200px 0;
}
@media (max-width: 576px) {
  .about .btn__center {
    padding: 150px 0;
  }
}

.awards__slider {
  position: relative;
  max-width: 400px;
  padding-bottom: 45px;
}
@media (max-width: 576px) {
  .awards__slider {
    max-width: 275px;
    padding-bottom: 30px;
  }
}

.swiper-button-prev, .swiper-button-next {
  width: 88px;
  height: 24px;
  top: auto;
  bottom: 0;
}
.swiper-button-prev::after, .swiper-button-next::after {
  display: none;
}
@media (max-width: 576px) {
  .swiper-button-prev, .swiper-button-next {
    width: 75px;
  }
}

.footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  padding-bottom: 50px;
}
.footer__wrap {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .footer__wrap {
    flex-direction: column-reverse;
  }
}
@media (max-width: 576px) {
  .footer__link {
    margin-bottom: 15px;
    text-align: center;
  }
}

.copyright {
  color: var(--dark-color);
}
@media (max-width: 576px) {
  .copyright {
    text-align: center;
  }
}

.link {
  color: var(--dark-color);
  margin-right: 40px;
}
.link:last-child {
  margin-right: 0;
}