.btn {
  font-family: 'Inter';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 18px 25px;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  min-width: 221px;
  min-height: 63px;
  color: var(--light-color);
  border-radius: 15px;
  background-color: var(--btn-color);
  transition: all .3s ease;
  &:hover, &:active, &:focus {
    color: var(--light-color);
    background-color: var(--btn-hover);
  }
  @include small-tablet {
    font-size: 16px;
    line-height: 130%;
    min-height: 56px;
  }
  @include mobile {
    width: 100%;
    padding: 18px 25px;
    min-width: 100%;
  }
  &__white {
    background-color: var(--light-color);
    color: var(--btn-color);
    &:hover {
      background-color: var(--btn-white-hover);
      color: var(--btn-color);
    }
  }

  &-green {
    background-color: var(--green-color-accent);
    min-width: 336px;
    min-height: 57px;
    &:hover {
      background-color: var(--green-color);
    }
  }
}

.btn__header {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: var(--dark-color);
  border: 1px solid var(--dark-color);
  border-radius: 8px;
  padding: 6px 8px;
  margin-left: auto;
  margin-right: 20px;
  &:hover, &:active, &:focus {
    color: var(--light-color);
    background-color: var(--btn-color);
    border: 1px solid var(--btn-color);
  }
  @include mobile {
    display: none;
  }
}

.form__btn {
  padding: 16px 25px;
  max-height: 52px;
  max-width: 336px;
  margin: 0 auto;
  @include small-tablet {
    width: 100%;
  }
}
.btn__center {
  text-align: center;
}

.slide__btn {
  stroke: var(--dark-color);
  @include mobile {
    stroke: var(--light-color);
    height: 23px;
  }
}
