.title {
  font-weight: 600;
  font-size: 64px;
  line-height: 130%;
  letter-spacing: -0.02em;
  &-white {
    color: var(--light-color);
  }
  span {
    color: var(--green-color-accent);
  }
  @include small-tablet {
    font-size: 48px;
    line-height: 130%;
  }
  &-blue {
    color: var(--btn-color);
  }
}
