.footer {
  font-weight: 400;
  font-size: 12px;
  line-height: 132%;
  padding-bottom: 50px;
  &__wrap {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column-reverse;
    }
  }
  &__link {
    @include mobile {
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

.copyright {
  color: var(--dark-color);
  @include mobile {
    text-align: center;
  }
}
.link {
  color: var(--dark-color);
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
}
