.team {
  background-color: var(--btn-color);
  @include high-tablet {
    .container-fluid {
      padding-right: 0;
    }
  }
  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include high-tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include small-tablet {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

    }
  }
  &__item {
    border: 1px solid rgba(163, 255, 222, 0.5);
    padding: 25px 40px 0 40px;
    color: var(--light-color);

    &:nth-child(-n+3) {
      border-bottom: none;
      padding-bottom: 110px;
      transform: translateY(1px);
      @include high-tablet {
        padding-bottom: 0;
      }
    }
    &:nth-child(1) {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
    &:nth-child(2) {
      border-bottom: 1px solid rgba(163, 255, 222, 0.5);

    }
    &:nth-child(3) {
      border-left: none;
      border-right: none;
    }
    &:nth-child(4) {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
    &:nth-child(5) {
      border-width: 1px;
      border-style: solid;
      border-image:
        linear-gradient(
          to bottom,
          rgba(163, 255, 222, 0.5),
          rgba(163, 255, 222, 0)
        ) 1 100%;
      border-bottom: none;
    }
    &:nth-child(6) {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
    @include high-tablet {
      border: none!important;
    }
    @include small-tablet {
      flex: 0 0 auto;
      width: 340px;
      padding: 25px 40px 0 0px;
    }
  }

  &__img {
    position: relative;
    margin-bottom: 20px;
    @include mobile {
      width: 150px;
      height: 150px;
    }
  }
  &__experience {
    position: absolute;
    left: 145px;
    top: 24px;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
    background: linear-gradient(98.83deg, #55D086 7.54%, #3A4FCD 75.18%);
    border-radius: 15px;
    @include high-tablet {
      font-size: 16px;
      padding: 8px 13px;
      width: 170px;
    }
    @include mobile {
      left: 120px;
    }
  }
  &__name {
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: -0.02em;
    @include small-tablet {
      font-size: 24px;
    }
  }
  &__post, &__techniques, &__technique {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.02em;
  }
  &__technique {
    font-weight: 600;
  }
  &__post {
    margin-bottom: 25px;
    @include mobile {
      margin-bottom: 10px;
    }
  }
  &__techniques {
    max-width: 375px;
  }
}
