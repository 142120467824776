.about {
  position: relative;
  padding: 90px 0 0;
  background-color: var(--light-color);
  background-image: url(../img/about__bg.svg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;
  z-index: 10;
  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 900px;
    background-image: url(../img/ball.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    z-index: -1;
    @include mobile {
      background-image: url(../img/ball-mob.png);
    }
  }
  @include small-tablet {
    padding: 50px 0 0;
    background-image: none;
  }
  .title {
    margin-bottom: 40px;
    @include small-tablet {
      margin-bottom: 20px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    max-width: 585px;
    span {
      font-weight: 600;
    }
    &-mb {
      margin-bottom: 40px;
      @include small-tablet {
        margin-bottom: 15px;
      }
    }
    @include small-tablet {
      font-size: 16px;
    }
  }
  &__wrap {
    padding-left: 120px;
    display: flex;
    align-items: center;
    @include high-desktop {
      padding-left: 0;
    }
    @include high-tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__left {
    margin-right: 30px;
    @include high-tablet {
      margin-bottom: 40px;
      margin-right: 0;
    }
  }
  .btn {
    min-width: 300px;
  }
  .btn__center {
    padding: 200px 0;
    @include mobile {
      padding: 150px 0;
    }
  }
}
.awards__slider{
  position: relative;
  max-width: 400px;
  padding-bottom: 45px;
  @include mobile {
    max-width: 275px;
    padding-bottom: 30px;
  }
}

.swiper-button-prev, .swiper-button-next {
  width: 88px;
  height: 24px;
  top: auto;
  bottom: 0;
  &::after {
    display: none;
  }
  @include mobile {
    width: 75px;
  }
}

